export default {
  bg: {
    white: "#ffffff",
    purple: "#8276bb",
    blueLight:"#4d54e8",
    blueMedium: "#5259F9",
    yellowDark: "#FFA800",
  },
  text: {
    white: "#ffffff",
    yellowDark: "#FFA800",
    disabled: "#b7b7b7",
    yellow: "#FFCF55",
    lightGray:"#A09BB6",
    lightGreen: "#09CB9C"
  },
  btn: {
    mediumPurple: "#504B68"
  },
  border: {
    light: "#C8C8C8",
  },
  fontSizes: {
    xx: "12px",
    xxs: "14px",
    xs: "16px",
    sm: "18px",
    md: "20px",
    lg: "22px",
    xl: "24px",
    xxl: "25px",
    xxxl: "28px",
    xxx: "34px",
    xxxxl: "42px",
    big: "52px",
  },
  mediaQueries: {
    width: {
      smallMobile: {
        min: "320px",
        max: "480px",
      },
      largeMobile: {
        max: "767px",
      },
      tablets: {
        min: "768px",
        max: "1024px",
      },
      laptops: {
        min: "1025px",
        max: "1280px",
      },
      desktops: {
        min: "1281px",
      },
    },
  },
};
