import { useEffect, useState } from "react";
import "react-toastify/dist/ReactToastify.css";

import { EGames } from "@common/types/dashboard.types";

import useSound from "@utils/hooks/useSound";
import LobbyMusicBHB from "@assets/sounds/spinner/LobbyMusicBHB.mp3";
import LobbyMusicFIA from "@assets/sounds/fia/LobbyMusicFIA.mp3";
import LobbyMusicTZ from "@assets/sounds/tz/LobbyMusicTZ.mp3";
import LobbyMusicTO from "@assets/sounds/to/LobbyMusicTO.mp3";
import LobbyMusicPTG from "@assets/sounds/ptg/LobbyMusicPTG.mp3";
import LobbyMusicPEN from "@assets/sounds/pen/LobbyMusicPEN.mp3";

export const useMusic = (soundData: boolean, initialTrack: EGames | null = null) => {
  const [gameTrack, setGameTrack] = useState<EGames | null>(initialTrack)

  const musicConfig = {
    interrupt: true,
    loop: true,
    volume: soundData ? 1 : 0,
    preload: false,
  }

  const [playMusicBHB, { duration: musicDurationBHB, stop: stopMusicBHB, sound: soundMusicBHB }] = useSound(LobbyMusicBHB, musicConfig);
  const [playMusicFIA, { duration: musicDurationFIA, stop: stopMusicFIA, sound: soundMusicFIA }] = useSound(LobbyMusicFIA, musicConfig);
  const [playMusicTZ, { duration: musicDurationTZ, stop: stopMusicTZ, sound: soundMusicTZ }] = useSound(LobbyMusicTZ, musicConfig);
  const [playMusicTO, { duration: musicDurationTO, stop: stopMusicTO, sound: soundMusicTO }] = useSound(LobbyMusicTO, musicConfig);
  const [playMusicPTG, { duration: musicDurationPTG, stop: stopMusicPTG, sound: soundMusicPTG }] = useSound(LobbyMusicPTG, musicConfig);
  const [playMusicPEN, { duration: musicDurationPEN, stop: stopMusicPEN, sound: soundMusicPEN }] = useSound(LobbyMusicPEN, musicConfig);

  const [isPlayedBHB, setIsPlayedBHB] = useState(false);
  const [isPlayedFIA, setIsPlayedFIA] = useState(false);
  const [isPlayedTZ, setIsPlayedTZ] = useState(false);
  const [isPlayedTO, setIsPlayedTO] = useState(false);
  const [isPlayedPTG, setIsPlayedPTG] = useState(false);
  const [isPlayedPEN, setIsPlayedPEN] = useState(false);

  const stopAllSounds = () => {
    stopMusicBHB();
    stopMusicFIA();
    stopMusicTZ();
    stopMusicTO();
    stopMusicPTG();
    stopMusicPEN();
    setIsPlayedBHB(false);
    setIsPlayedFIA(false);
    setIsPlayedTZ(false);
    setIsPlayedTO(false);
    setIsPlayedPTG(false);
    setIsPlayedPEN(false);
  }

  const stop = () => {
    setGameTrack(null);
    stopAllSounds();
  };

  useEffect(() => {
    switch (gameTrack) {
      case EGames.BearHamsterBull: {
        soundMusicBHB?.load();

        if (musicDurationBHB && !isPlayedBHB) {
          stopAllSounds();
          playMusicBHB();
          setIsPlayedBHB(true);
        }

        break;
      }

      case EGames.FiveInAll: {
        soundMusicFIA?.load();

        if (musicDurationFIA && !isPlayedFIA) {
          stopAllSounds();
          playMusicFIA();
          setIsPlayedFIA(true);
        }

        break;
      }

      case EGames.TotalZero: {
        soundMusicTZ?.load();

        if (musicDurationTZ && !isPlayedTZ) {
          stopAllSounds();
          playMusicTZ();
          setIsPlayedTZ(true);
        }

        break;
      }

      case EGames.TwentyOne: {
        soundMusicTO?.load();

        if (musicDurationTO && !isPlayedTO) {
          stopAllSounds();
          playMusicTO();
          setIsPlayedTO(true);
        }

        break;
      }

      case EGames.Pentagram: {
        soundMusicPTG?.load();

        if (musicDurationPTG && !isPlayedPTG) {
          stopAllSounds();
          playMusicPTG();
          setIsPlayedPTG(true);
        }

        break;
      }

      case EGames.Penalty: {
        soundMusicPEN?.load();

        if (musicDurationPEN && !isPlayedPEN) {
          stopAllSounds();
          playMusicPEN();
          setIsPlayedPEN(true);
        }

        break;
      }

      default: {
        stopAllSounds();
        break;
      }
    }

    return () => {
      stopAllSounds();
    }
  }, [
    musicDurationBHB,
    musicDurationFIA,
    musicDurationTZ,
    musicDurationTO,
    musicDurationPTG,
    musicDurationPEN,
    gameTrack,
  ]);

  const play = (game?: EGames | null) => {
    setGameTrack(game ?? null);
  }

  return {
    play,
    stop,
  }
}
