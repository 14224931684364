import styled from "styled-components/macro";
import {device} from "@shared/styles/device";
export const ToastContainer = styled.div `
  display: flex;
  border-radius: 4px;
`;


export const LeftSidebar = styled.div `
  display: flex;
`;
export const CenterSidebar = styled.div `
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: start;
  margin-left: 16px;
`;

export const RightSidebar = styled.div `
  display: flex;
`;

export const ConnectionLostImageContainer = styled.div `
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f2994a;
  padding: 10px;
  width: 50px;
  height: 50px;
  border-radius: 10px;
`;

export const ConnectionRestoreImageContainer = styled(ConnectionLostImageContainer)`
  background: #27ae60;
`;


export const ToastTitle = styled.h4 `
  margin-top: 0;
  font-size: 18px;
  color: #fff;
  font-weight: 700;
  margin-bottom: 10px;
`;

export const ToastMessage = styled.p `
  color: #fff;
  font-size: 14px;
  font-weight: 500;
  @media ${device.mobileLarge}, ${device.mobileSmall} {
    font-size: 14px;
  }
`;
