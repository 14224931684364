import { fromJS, Map } from 'immutable';
import { Reducer } from 'redux';
import { dashboardActionTypes } from './constants';
import {
  IGetMainResponseModel,
  IStatisticRound,
  IDuels,
  IGetStatusBarModel,
  ILastPlayer,
  ISocketGameModel,
  IGame,
  EPenaltyRole,
} from "@common/types/dashboard.types";

const statisticRoundInitial: IStatisticRound = {
  competitorId: 0,
  competitorNickName: '',
  competitorWinRate: 0,
  currencyCode: 'USDT',
  dateStartRound: null,
  gameName: '',
  gameResult: null,
  imageCompetitor: null,
  imagePlayer: null,
  isReady: null,
  keys: [],
  loseNumber: 0,
  penaltyRoundStatistic: [],
  playerId: 0,
  playerNickName: '',
  playerPenaltyRole: EPenaltyRole.Striker,
  playerWinRate: 0,
  rate: 0,
  roomId: 0,
  roundNumber: 1,
  roundNumberPenalty: 1,
  roundResult: null,
  selectValuePlayer: null,
  statisticRoundId: 0,
  timerExpired: false,
  winNumber: 0,
};

interface DashboardState {
  isLoading: boolean;
  error: null | any;
  mainInfo: null | IGetMainResponseModel;
  statisticRoundValue: IStatisticRound;
  allDuels: null | IDuels;
  games: null | IGame[];
  statusBar: null | IGetStatusBarModel;
  lastPlayerList: null | ILastPlayer[];
  lastPlayers: null | ILastPlayer[];
  playGameInfo: ISocketGameModel;
  selectedGame: null | IGame;
  selectedRate: null | number;
  selectedCoin: null | string;
  userWaitingListId: null | number;
  rewardsForSignUp: boolean;
  beforeGame: boolean;
  profileBack: string;
  gaStatistics: any;
  isTelegram: boolean;
  keys: string[];
}

const initialState: DashboardState = {
  isLoading: false,
  error: null,
  mainInfo: null,
  statisticRoundValue: statisticRoundInitial,
  allDuels: null,
  games: null,
  statusBar: null,
  lastPlayerList: null,
  lastPlayers: null,
  playGameInfo: {
    statisticRound: null,
    roundResult: null,
    gameResult: null,
  },
  selectedGame: null,
  selectedRate: null,
  selectedCoin: "USDT",
  userWaitingListId: null,
  rewardsForSignUp: false,
  beforeGame: true,
  profileBack: "/",
  gaStatistics: {},
  isTelegram: false,
  keys: [],
};

// @ts-ignore
const dashboardReducer: Reducer<Map<string, any>, dashboardActionTypes> = (
  state = fromJS(initialState),
  { type, payload }
) => {
  switch (type) {
    case dashboardActionTypes.GET_MAIN_INFO_SUCCESS:
      return state.merge({
        isLoading: false,
        mainInfo: payload,
        userWaitingListId: payload.userWaitingList.id || null,
      });

    case dashboardActionTypes.GET_STATISTIC_ROUND_SUCCESS:
      return state.merge({
        statisticRoundValue: payload && payload.statisticRoundId ? payload : statisticRoundInitial,
        keys: payload && payload.keys ? payload.keys : [],
      });
    
    case dashboardActionTypes.GET_ALL_DUELS_SUCCESS:
      return state.merge({
        isLoading: false,
        allDuels: payload ? payload : null,
      });
    
    case dashboardActionTypes.GET_GAMES_SUCCESS:
      return state.merge({
        isLoading: false,
        games: payload,
      });

    case dashboardActionTypes.GET_STATUS_BAR_SUCCESS:
      return state.merge({
        isLoading: false,
        statusBar: payload
      });

    case dashboardActionTypes.LAST_PLAYER_LIST:
      return state.merge({
        lastPlayerList: payload
      });

    case dashboardActionTypes.LAST_PLAYERS:
      return state.merge({
        lastPlayers: payload
      });

    case dashboardActionTypes.POST_PLAY_GAME:
      return state.merge({
        isLoading: true,
        error: null,
        userWaitingListId: null,
        keys: [],
      });

    case dashboardActionTypes.POST_PLAY_GAME_SUCCESS:
      return state.merge({
        isLoading: false,
        userWaitingListId: payload.userWaitingListId,
      });

    case dashboardActionTypes.POST_GA_STATISTICS_SUCCESS:
      return state.merge({
        isLoading: false,
        gaStatistics: payload,
        isTelegram: payload.data.isTelegram,
      });

    case dashboardActionTypes.DELETE_CREATED_GAME_SUCCESS:
      return state.merge({
        isLoading: false,
        userWaitingListId: null,
      });

    case dashboardActionTypes.SOCKET_MESSAGE_GAME:
      return state.merge({
        playGameInfo: payload
      });

    case dashboardActionTypes.SELECT_GAME:
      return state.merge({
        selectedGame: payload,
      });

    case dashboardActionTypes.SELECT_RATE:
      return state.merge({
        selectedRate: payload,
      });

    case dashboardActionTypes.SELECT_COIN:
      return state.merge({
        selectedCoin: payload,
      });

    case dashboardActionTypes.REWARDS_FOR_SIGNUP:
      return state.merge({
        rewardsForSignUp: payload
      });

    case dashboardActionTypes.BEFORE_GAME:
      return state.merge({
        beforeGame: payload
      });

    case dashboardActionTypes.PROFILE_BACK:
      return state.merge({
        profileBack: payload
      });

    case dashboardActionTypes.CLEAR_ERROR:
      return state.merge({
        error: null,
      });
    
    case dashboardActionTypes.GET_MAIN_INFO:
    case dashboardActionTypes.GET_STATISTIC_ROUND:
    case dashboardActionTypes.GET_ALL_DUELS:
    case dashboardActionTypes.GET_GAMES:
    case dashboardActionTypes.GET_STATUS_BAR:
    case dashboardActionTypes.GET_TO_VALUE:
    case dashboardActionTypes.POST_GA_STATISTICS:
    case dashboardActionTypes.POST_GAME_CHOICE_VALUE:
    case dashboardActionTypes.POST_GAME_READY:
    case dashboardActionTypes.POST_TIMER_EXPIRED:
    case dashboardActionTypes.POST_ROUND_STARTED:
    case dashboardActionTypes.DELETE_CREATED_GAME:
    case dashboardActionTypes.CLOSE_ROOM:
    case dashboardActionTypes.AGREEMENT_RULES:
      return state.merge({
        isLoading: true,
        error: null,
      });

    case dashboardActionTypes.GET_TO_VALUE_SUCCESS:
    case dashboardActionTypes.POST_GAME_CHOICE_VALUE_SUCCESS:
    case dashboardActionTypes.POST_GAME_READY_SUCCESS:
    case dashboardActionTypes.POST_TIMER_EXPIRED_SUCCESS:
    case dashboardActionTypes.POST_ROUND_STARTED_SUCCESS:
    case dashboardActionTypes.CLOSE_ROOM_SUCCESS:
    case dashboardActionTypes.AGREEMENT_RULES_SUCCESS:
      return state.merge({
        isLoading: false,
      });

    case dashboardActionTypes.GET_MAIN_INFO_FAIL:
    case dashboardActionTypes.GET_STATISTIC_ROUND_FAIL:
    case dashboardActionTypes.GET_ALL_DUELS_FAIL:
    case dashboardActionTypes.GET_GAMES_FAIL:
    case dashboardActionTypes.GET_STATUS_BAR_FAIL:
    case dashboardActionTypes.GET_TO_VALUE_FAIL:
    case dashboardActionTypes.POST_PLAY_GAME_FAIL:
    case dashboardActionTypes.POST_GA_STATISTICS_FAIL:
    case dashboardActionTypes.POST_GAME_CHOICE_VALUE_FAIL:
    case dashboardActionTypes.POST_GAME_READY_FAIL:
    case dashboardActionTypes.POST_TIMER_EXPIRED_FAIL:
    case dashboardActionTypes.POST_ROUND_STARTED_FAIL:
    case dashboardActionTypes.DELETE_CREATED_GAME_FAIL:
    case dashboardActionTypes.CLOSE_ROOM_FAIL:
    case dashboardActionTypes.AGREEMENT_RULES_FAIL:
      return state.merge({
        isLoading: false,
        error: payload
      });

    default:
      return state;
  }
};

export default dashboardReducer;
