import { Dispatch } from "react";
import { AxiosResponse } from "axios";
import {refreshToken} from "@redux/auth/actions";
import axiosInstance from './configAPI';

interface IResponseError extends Error {
  response?: {
    status: number,
    data: {
      code: string,
    }
  }
}

interface IOptions {
  url: string;
  data?: object;
  method?: string;
  params?: object;
}

const handleUnauthorized = async (
  dispatch: Dispatch<any>,
  retry: any,
  options: IOptions
): Promise<any> => {
  if (!retry) {
    try {
      // @ts-ignore
      await refreshToken()(dispatch);
    } catch (error) {
      return Promise.reject(error);
    }

    try {
      return await http(dispatch, true)(options);
    } catch (reError) {
      return Promise.reject(reError);
    }
  }

  return Promise.reject(new Error('Maximum refresh token attempts exceeded'));
};

const http = (dispatch: Dispatch<any>, retry?: boolean) => async (options: IOptions): Promise<AxiosResponse> => {
  try {
    return await axiosInstance(options);
  } catch (error) {
    const err = error as IResponseError;

    if (!err.response) {
      console.error(`Network error [REQUEST FAILED: ${options.url}]`, error);
      return Promise.reject(err);
    }

    if (err.response.status === 401) {
      console.log('Unauthorized [401] :: refreshing access token');
      try {
        return await handleUnauthorized(dispatch, retry, options);
      } catch (e) {
        return Promise.reject(e);
      }
    }

    return Promise.reject(err);
  }
};

export default http;
