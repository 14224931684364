import { toast } from "react-toastify";

export const errorsMessagesServer = {
  alreadyAnotherDevice:
    "You are already waiting for the game on another device",
  notEnoughMoney: "Not enough funds",
  notEnoughWintickets: "Not enough wintickets",
};
export const serverErrorsMessages = (error: string) => {
  switch (error) {
    case "Invalid credentials.":
      return "errors.checkCredits";

    case "This email already exists":
      return "errors.emailExist";

    case "There is already an account with this email":
      return "errors.emailExist";

    case "User not found":
      return "errors.UserNotFound";

    case "You have been banned":
      return "errors.YouHaveBeenBanned";

    case "Not enough funds":
      return null;

    case "Not enough wintickets":
      return null;

    case errorsMessagesServer.alreadyAnotherDevice:
      return "errors.alreadyAnotherDevice";

    case "JWT Refresh Token Not Found":
      return null;

    case "JWT Token not found":
      return null;

    case "Invalid JWT Token":
      return null;

    case "Invalid JWT Refresh Token":
      return null;

    case "refreshTokenErrors":
      return null;

    default:
      return "errors.tryAgain";
  }
};

export const showErrorToast = (error: any, intl: any) => {
  const errorMessage = serverErrorsMessages(
    error ? error : "refreshTokenErrors",
  );

  if (errorMessage) {
    toast.error(intl.formatMessage({ id: errorMessage }));
  }
};

export const timerSeconds = 15000;
