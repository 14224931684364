// @ts-ignore
import { combineReducers } from "redux-immutable";

import settings from "@redux/settings";
import auth from "@redux/auth";
import dashboard from "@redux/dashboard";
import image from "@redux/image";
import profile from "@redux/profile";
import withdrawal from "@redux/withdrawal";
import transaction from "@redux/transaction";
import deposit from "@redux/deposit";

export const rootReducer = combineReducers({
  auth,
  dashboard,
  settings,
  image,
  profile,
  withdrawal,
  transaction,
  deposit
});
