import React from 'react';
import ConnectionLost from "@icons/components/ConnectionLost";
import ConnectionRestore from "@icons/components/ConnectionRestore";
import {
  ToastContainer,
  LeftSidebar,
  ToastTitle,
  CenterSidebar,
  ToastMessage,
  ConnectionLostImageContainer,
  ConnectionRestoreImageContainer,
} from "./My.styled";

import {FormattedMessage} from "react-intl";

interface CustomToastProps {
  closeToast?: () => void;
  type: 'connectionClosed' | 'connectionRestored' | string;
}

const CustomToast: React.FC<CustomToastProps> = ({ closeToast, type }) => {
  let content;

  switch (type) {
    case "connectionClosed":
      content = {
        title: "connections.connectionClosed",
        message: "connections.connectionLost",
        leftImage: <ConnectionLostImageContainer><ConnectionLost/></ConnectionLostImageContainer>,
        rightImage: null
      };
      break;

    case "connectionRestored":
      content = {
        title: "connections.connectionRestored",
        message: "connections.connectionRestore",
        leftImage: <ConnectionRestoreImageContainer><ConnectionRestore /></ConnectionRestoreImageContainer>,
        rightImage: null
      };
      break;

    case "connectionWeak":
      content = {
        title: "connections.connectionWeak",
        message: "connections.connectionWeakMessage",
        leftImage: <ConnectionLostImageContainer><ConnectionLost/></ConnectionLostImageContainer>,
        rightImage: null
      };
      break;

    default:
      content = {
        title: "",
        message: " ",
        leftImage: null,
        rightImage: null,
      };
  }

  return (
    <ToastContainer>
      <LeftSidebar>{content.leftImage}</LeftSidebar>
      <CenterSidebar>
        <ToastTitle><FormattedMessage id={content.title}/></ToastTitle>
        <ToastMessage><FormattedMessage id={content.message}/></ToastMessage>
      </CenterSidebar>
    </ToastContainer>
  );
}

export default CustomToast;
