export const dashboardActionTypes = {
  GET_MAIN_INFO: 'GET_MAIN_INFO',
  GET_MAIN_INFO_SUCCESS: 'GET_MAIN_INFO_SUCCESS',
  GET_MAIN_INFO_FAIL: 'GET_MAIN_INFO_FAIL',

  GET_STATISTIC_ROUND: 'GET_STATISTIC_ROUND',
  GET_STATISTIC_ROUND_SUCCESS: 'GET_STATISTIC_ROUND_SUCCESS',
  GET_STATISTIC_ROUND_FAIL: 'GET_STATISTIC_ROUND_FAIL',

  GET_ALL_DUELS: 'GET_ALL_DUELS',
  GET_ALL_DUELS_SUCCESS: 'GET_ALL_DUELS_SUCCESS',
  GET_ALL_DUELS_FAIL: 'GET_ALL_DUELS_FAIL',

  GET_GAMES: 'GET_GAMES',
  GET_GAMES_SUCCESS: 'GET_GAMES_SUCCESS',
  GET_GAMES_FAIL: 'GET_GAMES_FAIL',

  GET_STATUS_BAR: 'GET_STATUS_BAR',
  GET_STATUS_BAR_SUCCESS: 'GET_STATUS_BAR_SUCCESS',
  GET_STATUS_BAR_FAIL: 'GET_STATUS_BAR_FAIL',

  LAST_PLAYER_LIST: 'LAST_PLAYER_LIST',
  LAST_PLAYERS: 'LAST_PLAYERS',

  POST_PLAY_GAME: "POST_PLAY_GAME",
  POST_PLAY_GAME_SUCCESS: 'POST_PLAY_GAME_SUCCESS',
  POST_PLAY_GAME_FAIL: 'POST_PLAY_GAME_FAIL',

  GET_TO_VALUE: 'GET_TO_VALUE',
  GET_TO_VALUE_SUCCESS: 'GET_TO_VALUE_SUCCESS',
  GET_TO_VALUE_FAIL: 'GET_TO_VALUE_FAIL',

  POST_GAME_CHOICE_VALUE: "POST_GAME_CHOICE_VALUE",
  POST_GAME_CHOICE_VALUE_SUCCESS: 'POST_GAME_CHOICE_VALUE_SUCCESS',
  POST_GAME_CHOICE_VALUE_FAIL: 'POST_GAME_CHOICE_VALUE_FAIL',

  POST_GAME_READY: "POST_GAME_READY",
  POST_GAME_READY_SUCCESS: "POST_GAME_READY_SUCCESS",
  POST_GAME_READY_FAIL: "POST_GAME_READY_FAIL",

  POST_TIMER_EXPIRED: "POST_TIMER_EXPIRED",
  POST_TIMER_EXPIRED_SUCCESS: "POST_TIMER_EXPIRED_SUCCESS",
  POST_TIMER_EXPIRED_FAIL: "POST_TIMER_EXPIRED_FAIL",

  POST_ROUND_STARTED: "POST_ROUND_STARTED",
  POST_ROUND_STARTED_SUCCESS: "POST_ROUND_STARTED_SUCCESS",
  POST_ROUND_STARTED_FAIL: "POST_ROUND_STARTED_FAIL",

  DELETE_CREATED_GAME: "DELETE_CREATED_GAME",
  DELETE_CREATED_GAME_SUCCESS: "DELETE_CREATED_GAME_SUCCESS",
  DELETE_CREATED_GAME_FAIL: "DELETE_CREATED_GAME_FAIL",

  CLOSE_ROOM: "CLOSE_ROOM",
  CLOSE_ROOM_SUCCESS: "CLOSE_ROOM_SUCCESS",
  CLOSE_ROOM_FAIL: "CLOSE_ROOM_FAIL",

  SOCKET_MESSAGE_GAME: "SOCKET_MESSAGE_GAME",

  AGREEMENT_RULES:"AGREEMENT_RULES",
  AGREEMENT_RULES_SUCCESS:"AGREEMENT_RULES_SUCCESS",
  AGREEMENT_RULES_FAIL:"AGREEMENT_RULES_FAIL",

  SELECT_GAME: "SELECT_GAME",
  SELECT_RATE: "SELECT_RATE",
  SELECT_COIN: "SELECT_COIN",

  CLEAR_ERROR: 'CLEAR_ERROR',

  REWARDS_FOR_SIGNUP: 'REWARDS_FOR_SIGNUP',

  BEFORE_GAME: 'BEFORE_GAME',

  PROFILE_BACK: 'PROFILE_BACK',

  POST_GA_STATISTICS: "POST_GA_STATISTICS",
  POST_GA_STATISTICS_SUCCESS: 'POST_GA_STATISTICS_SUCCESS',
  POST_GA_STATISTICS_FAIL: 'POST_GA_STATISTICS_FAIL',
};
