import { fromJS, Map } from "immutable";
import { Reducer } from "redux";
import { withdrawalActionTypes } from "./constants";

interface WithdrawalState {
  isLoading: boolean;
  error: null | any;
}

const initialState: WithdrawalState = {
  isLoading: false,
  error: null,
};

// @ts-ignore
const withdrawalReducer: Reducer<Map<string, any>, withdrawalActionTypes> = (
  state = fromJS(initialState),
  { type, payload },
) => {
  switch (type) {
    case withdrawalActionTypes.POST_WITHDRAWAL:
      return state.merge({
        isLoading: true
      });

    case withdrawalActionTypes.POST_WITHDRAWAL_SUCCESS:
      return state.merge({
        isLoading: false,
      });

    case withdrawalActionTypes.POST_WITHDRAWAL_FAIL:
      return state.merge({
        isLoading: false,
        error: payload
      });

    case withdrawalActionTypes.RESET_ALL:
      return fromJS(initialState);

    default:
      return state;
  }
};

export default withdrawalReducer;
