export const authActionTypes = {
  RESET_ALL: "RESET_ALL",

  SIGN_IN: 'SIGN_IN',
  SIGN_IN_SUCCESS: 'SIGN_IN_SUCCESS',
  SIGN_IN_FAIL: 'SIGN_IN_FAIL',

  SIGN_OUT: 'SIGN_OUT',
  SIGN_OUT_SUCCESS: 'SIGN_OUT_SUCCESS',
  SIGN_OUT_FAIL: 'SIGN_OUT_FAIL',

  SIGN_UP: 'SIGN_UP',
  SIGN_UP_SUCCESS: 'SIGN_UP_SUCCESS',
  SIGN_UP_FAIL: 'SIGN_UP_FAIL',

  POST_FORGOT_PASSWORD: "POST_FORGOT_PASSWORD",
  POST_FORGOT_PASSWORD_SUCCESS: "POST_FORGOT_PASSWORD_SUCCESS",
  POST_FORGOT_PASSWORD_FAIL: "POST_FORGOT_PASSWORD_FAIL",

  CHECK_TOKEN_SUCCESS: 'CHECK_TOKEN_SUCCESS',
  CHECK_TOKEN_FAIL: 'CHECK_TOKEN_FAIL',

  POST_RESET_PASSWORD: "POST_RESET_PASSWORD",
  POST_RESET_PASSWORD_SUCCESS: "POST_RESET_PASSWORD_SUCCESS",
  POST_RESET_PASSWORD_FAIL: "POST_RESET_PASSWORD_FAIL",


  CLEAR_ERROR: 'CLEAR_ERROR',
};
