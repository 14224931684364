import {
  authActionTypes,
} from "@redux/auth/constants";
import {Reducer} from "redux";
import {fromJS, Map} from "immutable";
import {dashboardActionTypes} from "@redux/dashboard/constants";

interface initialStateInterface {
  lang: string
  isLoading: boolean;

}


const initialState: initialStateInterface = {
  lang: 'en',
  isLoading: false,
};

// @ts-ignore
const settingReducer: Reducer<Map<string, any>, dashboardActionTypes> = (
  state = fromJS(initialState),
  { type, payload }
) => {
  switch (type) {
    case authActionTypes.RESET_ALL:
      return initialState;

    default:
      return state;
  }
};

export default settingReducer;
