import { fromJS, Map } from "immutable";
import { Reducer } from "redux";
import { profileActionTypes } from "./constants";
import { IGetProfileResponseModel } from "@common/types/profile.types";
import { getValueFromLocalStorage } from "@services/tokenStorageService";

interface AuthState {
  isLoading: boolean;
  error: null | any;
  profileInfo: null | IGetProfileResponseModel;
  sound: boolean;
  selectedSortListOfDuels: any;
}

const initialState: AuthState = {
  isLoading: false,
  error: null,
  profileInfo: null,
  selectedSortListOfDuels: null,
  sound: getValueFromLocalStorage('sound') ? JSON.parse(getValueFromLocalStorage('sound')) : true,
};

// @ts-ignore
const profileReducer: Reducer<Map<string, any>, profileActionTypes> = (
  state = fromJS(initialState),
  { type, payload },
) => {
  switch (type) {
    case profileActionTypes.GET_PROFILE_INFO:
      return state.merge({
        isLoading: true,
      });

    case profileActionTypes.GET_PROFILE_INFO_SUCCESS:
      return state.merge({
        isLoading: false,
        profileInfo: payload,
      });

    case profileActionTypes.GET_PROFILE_INFO_FAIL:
      return state.merge({
        isLoading: false,
        error: payload,
      });

    case profileActionTypes.CLEAR_ERROR:
      return state.merge({
        error: null,
      });

    case profileActionTypes.SET_SELECTED_SORT_LIST_OF_DUELS:
      return state.merge({
        selectedSortListOfDuels: payload,
      });

    case profileActionTypes.SOUND_SWITCHER:
      return state.merge({
        sound: payload,
      })

    default:
      return state;
  }
};

export default profileReducer;
