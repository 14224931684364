export const imageActionTypes = {
  RESET_ALL: "RESET_ALL",

  ADD_IMAGE: 'ADD_IMAGE',
  ADD_IMAGE_SUCCESS: 'ADD_IMAGE_SUCCESS',
  ADD_IMAGE_FAIL: 'ADD_IMAGE_FAIL',

  DELETE_IMAGE: 'DELETE_IMAGE',
  DELETE_IMAGE_SUCCESS: 'DELETE_IMAGE_SUCCESS',
  DELETE_IMAGE_FAIL: 'DELETE_IMAGE_FAIL',

 

  CLEAR_ERROR: 'CLEAR_ERROR',
};