export const withdrawalActionTypes = {
  RESET_ALL: "RESET_ALL",

  POST_WITHDRAWAL: 'POST_WITHDRAWAL',
  POST_WITHDRAWAL_SUCCESS: 'POST_WITHDRAWAL_SUCCESS',
  POST_WITHDRAWAL_FAIL: 'POST_WITHDRAWAL_FAIL',

 

  CLEAR_ERROR: 'CLEAR_ERROR',
};