import React from "react";
import axios from "axios";
import { AppConfig } from "@services/config";
import authStateService from "@services/authStateService";
import { toast } from "react-toastify";
import CustomToastMessages from "@components/Unstructured/CustomToastMessages";

const authState = authStateService();

const axiosInstance: any = axios.create({
  baseURL: AppConfig.apiURL,
  headers: {
    "Content-Type": "application/json",
  },
});

const pendingRequests: any = {};
let interval: any = null;

const checkPendingTime = () => {
  let isWeakConnection = false;

  const now = Date.now();

  for (let i = 0; i < Object.keys(pendingRequests).length; i++) {
    const key = Object.keys(pendingRequests)[i];

    isWeakConnection = now - pendingRequests[key] > 5000;

    if (isWeakConnection) {
      delete pendingRequests[key];

      if (Object.keys(pendingRequests).length < 1) {
        clearInterval(interval);
        interval = null;
      }

      toast(<CustomToastMessages type={"connectionWeak"} />, {
        autoClose: 5000,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        toastId: "connectionWeak",
      });

      break;
    }
  }
};

// Add a request interceptor
axiosInstance.interceptors.request.use(
  // @ts-ignore
  async (config: any) => {
    const token = await authState.getAccessToken();

    pendingRequests[config.url] = Date.now();

    if (!interval) {
      interval = setInterval(checkPendingTime, 1000);
    }

    if (token) {
      return {
        ...config,
        headers: { ...config.headers, Authorization: `Bearer ${token}` },
        baseURL: config.url === 'play' || 
          config.url.indexOf('play/timer-expired') === 0 || 
          config.url.indexOf('play/round-started') === 0 
          ? AppConfig.nodeApiURL : AppConfig.apiURL,
      };
    }

    return config;
  },
  (error: any) => Promise.reject(error),
);

axiosInstance.interceptors.response.use(
  async (config: any) => {
    delete pendingRequests[config.config.url];

    return {
      ...config,
      success: config.status === 200 || config.status === 201,
    };
  },
  async (error: any) => {
    delete pendingRequests[error.config.url];

    return Promise.reject(error);
  },
);

export default axiosInstance;
