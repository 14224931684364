import { fromJS, Map } from 'immutable';
import { Reducer } from 'redux';
import { authActionTypes } from './constants';

interface AuthState {
  isAuthorized: boolean;
  isCheckingAuthorization: boolean;
  isLoading: boolean;
  error: null | any;
}

const initialState: AuthState = {
  isAuthorized: false,
  isCheckingAuthorization: true,
  isLoading: false,
  error: null,
};

// @ts-ignore
const authReducer: Reducer<Map<string, any>, authActionTypes> = (
  state = fromJS(initialState),
  { type, payload }
) => {
  switch (type) {
    case authActionTypes.SIGN_IN:
      return state.merge({
        isLoading: true,
        error: null,
      });

    case authActionTypes.SIGN_IN_SUCCESS:
      return state.merge({
        isLoading: false,
        error: null,
        isAuthorized: true,
      });

    case authActionTypes.SIGN_IN_FAIL:
      return state.merge({
        isLoading: false,
        error: payload,
        isAuthorized: false,
      });

    case authActionTypes.SIGN_UP:
      return state.merge({
        isLoading: true,
        error: null,
      });

    case authActionTypes.SIGN_UP_SUCCESS:
      return state.merge({
        isLoading: false,
        error: null,
        isAuthorized: true,
      });

    case authActionTypes.SIGN_UP_FAIL:
      return state.merge({
        isLoading: false,
        error: payload,
        isAuthorized: false,
      });


    case authActionTypes.POST_RESET_PASSWORD:
      return state.merge({
        isLoading: true,
        error: null,
      });

    case authActionTypes.POST_RESET_PASSWORD_SUCCESS:
      return state.merge({
        isLoading: false,
        error: null,
        isAuthorized: true,
      });

    case authActionTypes.POST_RESET_PASSWORD_FAIL:
      return state.merge({
        isLoading: false,
        error: payload,
        isAuthorized: false,
      });

    case authActionTypes.POST_FORGOT_PASSWORD:
      return state.merge({
        isLoading: true,
        error: null,
      });

    case authActionTypes.POST_FORGOT_PASSWORD_SUCCESS:
      return state.merge({
        isLoading: false,
        error: null,
        isAuthorized: false,
      });

    case authActionTypes.POST_FORGOT_PASSWORD_FAIL:
      return state.merge({
        isLoading: false,
        error: payload,
        isAuthorized: false,
      });

    case authActionTypes.CHECK_TOKEN_SUCCESS:
      return state.merge({
        isAuthorized: payload.isAuthorized,
        isCheckingAuthorization: false,
      });

    case authActionTypes.CHECK_TOKEN_FAIL:
      return state.merge({
        isAuthorized: false,
        isCheckingAuthorization: false,
      });

    case authActionTypes.SIGN_OUT_SUCCESS:
      return fromJS(initialState);


    case authActionTypes.RESET_ALL:
      return fromJS(initialState);

    default:
      return state;
  }
};

export default authReducer;
