// based on https://github.com/joshwcomeau/use-sound

import React, { useEffect } from 'react';
import { Howl } from 'howler';

export type SpriteMap = {
  [key: string]: [number, number];
};

export type HookOptions<T = any> = T & {
  id?: string;
  volume?: number;
  playbackRate?: number;
  interrupt?: boolean;
  soundEnabled?: boolean;
  sprite?: SpriteMap;
  onload?: () => void;
};

export interface PlayOptions {
  id?: string;
  forceSoundEnabled?: boolean;
  playbackRate?: number;
}

export type PlayFunction = (options?: PlayOptions) => void;

export interface ExposedData {
  sound: Howl | null;
  stop: (id?: string) => void;
  pause: (id?: string) => void;
  duration: number | null;
}

export type ReturnedValue = [PlayFunction, ExposedData];

export default function useSound<T = any>(
  src: string | string[],
  {
    id,
    volume = 1,
    playbackRate = 1,
    soundEnabled = true,
    interrupt = false,
    onload,
    ...delegated
  }: HookOptions<T> = {} as HookOptions
) {
  const HowlConstructor = React.useRef<any | null>(null);
  const isMounted = React.useRef(false);

  const [duration, setDuration] = React.useState<number | null>(null);

  const [sound, setSound] = React.useState<Howl | null>(null);

  const handleLoad = function () {
    if (typeof onload === 'function') {
      // @ts-ignore
      onload.call(this);
    }

    if (isMounted.current) {
      // @ts-ignore
      setDuration(this.duration() * 1000);
    }

    // @ts-ignore
    setSound(this);
  };

  useEffect(() => {
    import('howler').then((mod) => {
      if (!isMounted.current) {
        HowlConstructor.current = mod.Howl ?? mod.default.Howl;

        isMounted.current = true;

        setSound(
          new HowlConstructor.current({
            src: Array.isArray(src) ? src : [src],
            volume,
            rate: playbackRate,
            onload: handleLoad,
            ...delegated,
          })
        );
      }
    });

    return () => {
      isMounted.current = false;
    };
  }, []);

  React.useEffect(() => {
    if (HowlConstructor.current && sound) {
      setSound(
        new HowlConstructor.current({
          src: Array.isArray(src) ? src : [src],
          volume,
          onload: handleLoad,
          ...delegated,
        })
      );
    }
  }, [JSON.stringify(src)]);

  React.useEffect(() => {
    if (sound) {
      sound.volume(volume);
      sound.rate(playbackRate);
    }
  }, [volume, playbackRate]);

  const play: PlayFunction = React.useCallback(
    (options?: PlayOptions) => {
      if (typeof options === 'undefined') {
        options = {};
      }

      if (!sound || (!soundEnabled && !options.forceSoundEnabled)) {
        return;
      }

      if (interrupt) {
        sound.stop();
      }

      if (options.playbackRate) {
        sound.rate(options.playbackRate);
      }

      sound.play(options.id);
    },
    [sound, soundEnabled, interrupt]
  );

  const stop = React.useCallback(
    (id: any) => {
      if (!sound) {
        return;
      }
      sound.stop(id);
    },
    [sound]
  );

  const pause = React.useCallback(
    (id: any) => {
      if (!sound) {
        return;
      }
      sound.pause(id);
    },
    [sound]
  );

  const returnedValue: ReturnedValue = [
    play,
    {
      sound,
      stop,
      pause,
      duration,
    },
  ];

  return returnedValue;
}
